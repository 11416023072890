@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;

.label {
  margin-bottom: 9px;
}

.input {
  @include mix.input-box-styles;
}

.inputInput {
  width: 100%;
  height: 40px;
  padding: 10px 12px 11px;
  border: none;
  appearance: none;
  background: none;

  @include mix.input-text-styles;
}

.hasError {
  .input {
    border-color: red;
  }
}

.error {
  padding: 0 6px;
  margin-top: 8px;
  color: red;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
}
