@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;

.animate {
  opacity: 0;
  transition-delay: 0.25s;
  transition-duration: 1s;
}

.animateIn {
  opacity: 1;
}
