@use 'sass:math';
@use 'sass:string';
@use 'sass:color';
@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;

@function px-to-em($px, $context: 16px) {
  @return $px / $context * 1em;
}

$navbar-height: 60px;
$mobile-breakpoint: 800px;
$input-mobile-breakpoint: 576px;
$input-font-size: px-to-em(25px);
$input-height: px-to-em(60px);
$input-icon-size: px-to-em(55px);
$bg-z-index: 1;

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: white;
}

.containerInner {
  position: relative;
  z-index: $bg-z-index;
  display: flex;
  overflow: hidden;
  min-height: calc(100vh - var.$header-height);
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding: 0;
  background-color: white;

  > * {
    z-index: $bg-z-index + 1;
  }

  // Responsive styles
  @media (max-width: var.$desktop-breakpoint + 1) {
    min-height: calc(100vh - var.$mobile-header-height);
  }

  // Support for modern viewport units
  @supports (min-height: 100svh) {
    min-height: calc(100svh - var.$header-height);

    @media (max-width: var.$desktop-breakpoint + 1) {
      min-height: calc(100svh - var.$mobile-header-height);
    }
  }
}

// Add a rotated background
.containerInnerBackground {
  $initial-transform: translateZ(0) translate(-25%, -35%) rotate(-25deg) translateX(20%);

  --animated-bg-width: 4444px;
  position: absolute;
  z-index: $bg-z-index;
  top: 0;
  left: 0;
  width: calc(var(--animated-bg-width) * 2);
  height: 200%;
  margin-top: 0;
  margin-left: 0;
  
  background: white;
  background-position: 0 0;
  background-size: var(--animated-bg-width) auto;
  content: '';
  transform: $initial-transform;
  transform-origin: center;
  will-change: transform;

  @media (max-width: 2000px) {
    --animated-bg-width: 2222px;
  }

  @media (max-width: var.$tablet-breakpoint) {
    --animated-bg-width: 1111px;
  }

  // Animation for continuous scrolling (only moves one direction)
  @keyframes move-background {
    0% {
      transform: $initial-transform;
    }

    100% {
      transform: $initial-transform translateX(calc(var(--animated-bg-width) * -1));
    }
  }
}

.buttons {
  position: fixed;
  z-index: var.$navbar-z-index - 1;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  margin-right: 35px;
  margin-bottom: 35px;
  gap: 20px;

  @media (max-width: 640px) {
    left: 0;
    justify-content: center;
    margin-right: 0;
    margin-bottom: 25px;
  }

  .isGuest & {
    @media (max-width: $mobile-breakpoint) {
      right: 0;
      justify-content: center;
      margin-bottom: 25px;
      margin-left: 0;
    }
  }
}

.signInBtn {
  width: 180px;
  height: 58.5px;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 0 0 1px rgb(255 255 255 / 0.6);
  font-size: 18px;

  @media (max-width: var.$mobile-breakpoint-m) {
    display: none;
  }
}

.appStoreLinkIcon {
  width: 180px;
  height: auto;


  @media (max-width: var.$mobile-breakpoint-m) {
    display: none;
  }
}

.inputContainer {
  width: 100%;
}

.searchInput {
  height: $input-height;
  border: 2px solid white;
  background-color: white;
  border-radius: $input-height;
  box-shadow: 0 4px 12px rgb(0 0 0 / 0.1);
  line-height: $input-height;

  input {
    height: 100%;
    padding: 5px 30px;
    caret-color: rgb(66 66 66);
    color: rgb(66 66 66);
    font-size: $input-font-size;
    font-weight: 700;

    &[disabled] {
      color: var.$primary-text-color;
    }

    &:focus {
      border-color: black;
      outline: none;
    }
  }

  ::placeholder {
    color: transparent;
    opacity: 1;

    @media (max-width: $input-mobile-breakpoint) {
      color: currentColor;
    }
  }
}

.spinnerContainer {
  position: absolute;
  top: 50%;
  right: 17px;
  display: flex;
  transform: translateY(-50%);
}

.spinner {
  width: 30px;
  height: 30px;
  margin: auto;
  margin-left: 0;
}

.searchButton {
  @include mix.absolute-inset(0);
  right: 5px;
  width: $input-icon-size;
  height: $input-icon-size;
  padding: 0;
  border: none;
  margin: auto 0 auto auto;
  appearance: none;
  background: none;
  color: #ffd60a;
  cursor: pointer;
  font-size: 1em;
  transition: all 0.2s ease;

  &:active {
    transform: translate(1px, 1px);
  }
}

a {
  text-decoration-color: #ffd60a;
  text-decoration-thickness: 2px;
}

.centerContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 40px;
  margin: auto;

  @media (max-width: 640px) {
    padding: 20px;
  }
}

.instructions {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 8px 30px 35px;
  hyphens: initial;
  line-height: 1.5;

  @media (max-width: 640px), (max-height: 700px) {
    position: static;
    margin: 30px 20px;
  }
}

.instructionsTitle {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
}

.instructionsSubtitle {
  margin: 0;
  margin-top: 8px;
  color: color.adjust(var.$light-text-color, $lightness: -10%);
  font-size: 16px;
  line-height: 1.5;
}

.instructionsSubtitleLine {
  display: block;

  & + & {
    margin-top: 8px;
  }
}

.searchInputGroup {
  position: relative;
  display: flex;
  width: 100%;
}

.infoGroup {
  position: absolute;
  width: 100%;
  height: $input-height;
  padding: 0 22px;
  line-height: $input-height;
  pointer-events: none;
  text-align: left;

  > :global(.Typewriter) {
    overflow: hidden;
    color: rgb(66 66 66);
    font-size: $input-font-size;
    font-weight: 700;
    white-space: nowrap;
  }

  .typeWriterText {
    height: inherit;
    margin-left: 10px;
  }

  @keyframes blink {
    0%, 100% { visibility: visible !important; } /* Fully visible */
    50% { visibility: hidden !important; } /* Fully hidden, no fading */
  }
  
  .cursor {
    display: inline-block;
    margin-left: 2px;
    font-weight: bold;
    font-size: 1em;
    animation: blink 0.5s step-end infinite !important; /* Ensure instant blinking */
  }

  @media (max-width: $input-mobile-breakpoint) {
    display: none;
  }
}

.highlight {
  background-color: #ffd60aff;
}

.boldlink {
  border-bottom: 1px solid black;
  color: var.$primary-text-color;
  font-weight: 700;
}

.newHashtagContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 31px;
  margin-right: 38px;

  @media (max-width: var.$mobile-breakpoint-m) {
    justify-content: center;
    margin: 0;
    margin-bottom: 20px;
  }
}

.formContainer {
  transform: translateY(-60px);
  width: 100%;
  max-width: px-to-em(600px);
  padding: px-to-em(70px) px-to-em(60px) px-to-em(100px);
  margin: 0 auto;
  background-color: white;
  border-radius: px-to-em(100px);
  
  transform-origin: center;
  transition: transform 0.3s ease;

  font-size: clamp(12px, 0.9vw, 16px);

  @media (max-width: 480px) { // Adjust for small screens
    transform: translateY(25px); // Moves it down slightly
  }


}

.form {
  margin: 0 auto;
}

.formHeadline {
  margin-top: 0;
  margin-bottom: px-to-em(135px, 80px);
  color: black;
  font-family: 'GT Super', Arial, Helvetica, sans-serif;
  font-size: clamp(40px, 3vw, 60px);
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

@font-face {
  font-family: 'GT Super';
  font-style: normal;
  font-weight: 400; /* Adjust weights as necessary */
  src: url('gt-super.woff2') format('woff2'), url('gt-super.woff') format('woff');
}

.formSubHeadline {
  padding-right: px-to-em(40px, 25px);
  padding-left: px-to-em(40px, 25px);
  margin-bottom: px-to-em(80px, 25px);
  color: rgb(66 66 66);
  font-size: px-to-em(20px);
  font-weight: 400;
  text-align: center;
  text-shadow: 0 4px 12px rgb(0 0 0 / 0.1); /* Soft shadow for depth */
}

.bold {
  font-weight: 700;
}

.btn {
  width: 100%;
  height: px-to-em(50px);
  justify-content: center;
  padding: px-to-em(15px);
  border: none;
  background-color: #ffd60a;
  border-radius: px-to-em(8px);
  box-shadow: 0 px-to-em(4px) px-to-em(12px) rgb(0 0 0 / 0.1);
  color: black;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.formButtons {
  display: flex;
  margin-top: px-to-em(30px);
  gap: px-to-em(15px);

  @media (max-width: $input-mobile-breakpoint) {
    flex-direction: column; /* Stack buttons vertically */
    gap: px-to-em(10px); /* Adjust spacing between buttons if needed */
  }
}

.btnShow:hover {
  background-color: #ffc107;
  color: black;
}

.btn,
.btnShow {
  flex-basis: 50%;
  flex-shrink: 1;
  font-size: px-to-em(20px);
  white-space: nowrap;
}

.btnCreate {
  min-width: 0;
  letter-spacing: -0.2px;

  [class*='btnIconPlus'] {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    margin-right: 6px;
  }

  &.btnCreate {
    background-color: #ffedb3;

    &:hover {
      background-color: #ffc107;
      color: black;
    }
  }
}

.formTitle {
  padding-bottom: 4px;
  margin: 0;
  margin-bottom: 25px;
  color: gray;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.formIcon {
  $icon-size: 18px;
  width: $icon-size;
  height: $icon-size;
  color: gray;

  &,
  path {
    fill: currentColor;
  }
}

.formIconContainer {
  position: absolute;
  top: 0;
  right: 0;
  padding: 12px;
  cursor: pointer;
}

.title {
  font-size: 17px;
  font-weight: 700;
}

.titleInner {
  margin-bottom: 45px;
}
