@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;
@use 'sass:color';
@use 'sass:math';

.container {
  position: fixed;
  z-index: var.$navbar-z-index + 1;
  top: 0;
  left: 18px;
  display: flex;
  width: calc(100vw - 45px);
  align-items: center;
  justify-content: space-between;
  background-color: #222;
  border-radius: 0 0 12px 12px;
  color: white;
  opacity: 0;
  transition: all 0.2s ease;
  visibility: hidden;
}

.containerVisible {
  opacity: 1;
  visibility: visible;
}

.left {
  display: flex;
  align-items: center;
  padding: 20px;
  font-size: 18px;
  font-weight: 500;
}

.right {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

$checkbox-size: 25px;

.checkbox {
  color: #555;
  font-family: var(--hashare-secondary-font-family);
  font-size: 14px;
  line-height: 17px;
}

.label {
  position: relative;
  display: flex;
  cursor: pointer;
  line-height: 1.4;

  &::before {
    width: $checkbox-size;
    height: $checkbox-size;
    box-sizing: border-box;
    flex-shrink: 0;
    border: 2px solid var.$main-color;
    margin-right: 15px;
    background-color: white;
    border-radius: math.div($checkbox-size, 6);
    content: '';
    opacity: 1;
    transition: all 0.2s ease;
  }

  &::after {
    position: absolute;
    top: math.div($checkbox-size, 5);
    left: math.div($checkbox-size, 2.8);
    display: none;
    width: math.div($checkbox-size, 5);
    height: math.div($checkbox-size, 2.5);
    box-sizing: content-box;
    border: solid;
    border-width: 0 2px 2px 0;
    border-color: black;
    content: '';
    transform: rotate(45deg);
  }
}

.checkboxCheckbox {
  display: none;

  &:checked ~ .label {
    &::before {
      border-color: var.$main-color;
      background-color: var.$main-color;
      opacity: 1;
    }

    &:hover::before {
      border-color: color.adjust(var.$main-color, $lightness: -6%);
      background-color: color.adjust(var.$main-color, $lightness: -6%);
    }

    &::after {
      display: block;
    }
  }
}

.iconCloseContainer {
  cursor: pointer;
}

.iconClose {
  margin-left: 20px;
  pointer-events: none;
}

.actionBtn {
  height: auto;
  padding: 7px 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: var.$main-color;
  color: black;
  font-size: 14px;

  & + & {
    margin-left: 20px;
  }
}

.actionBtnIcon {
  width: inherit;
  height: inherit;
}

.trashIcon {
  transform: translateY(-1px);
}

.actionBtnIconAltered {
  margin: 0;
}

.actionBtnCompact {
  padding-right: 8px;
  padding-left: 8px;
}

.actionBtnCount {
  padding-left: 2px;
}
