@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;
@use 'sass:color';

$default-bg-color: var.$main-color;
$default-border-radius: 5px;

.button {
  position: relative;
  display: inline-flex;
  height: 40px;
  align-items: center;
  padding: 10px 21px;
  border: 1px solid;
  border: none;
  appearance: none;
  background-color: white;
  border-radius: $default-border-radius;
  color: white;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  transition: all 0.2s ease;

  &:active {
    transform: translate(1px, 1px);
  }
}

.primary {
  background-color: $default-bg-color;

  @include mix.box-shadow-with-alpha(0 4px 9px $default-bg-color, 0.7);

  &:hover {
    @include mix.box-shadow-with-alpha(0 4px 15px $default-bg-color, 0.7);
  }

  &:active {
    @include mix.box-shadow-with-alpha(0 4px 3px $default-bg-color, 0.7);
  }

  &.disabled {
    @include mix.box-shadow-with-alpha(0 4px 9px $default-bg-color, 0.7);
  }
}

.icon {
  width: 17px;
  height: 17px;

  path {
    fill: currentColor;
  }
}

.iconRight {
  margin-right: -5px;
  margin-left: 5px;
}

.iconLeft {
  margin-right: 5px;
  margin-left: -5px;
}

.secondary {
  border: 1px solid var.$border-color;
  background-color: white;
  box-shadow: 0 2px 9px rgb(171 171 171 / 0.2);
  color: black;
  opacity: 1;
}

.dark {
  background-color: black;
  box-shadow: 0 2px 9px rgb(171 171 171 / 0.2);
  color: white;
}

.disabled {
  cursor: no-drop;
  opacity: 0.5;

  &:active {
    transform: none;
  }
}

.spinner {
  @include mix.absolute-inset(0);
  left: 10px;
  width: 24px;
  height: 24px;
  margin-top: auto;
  margin-bottom: auto;
}

.text {
  height: auto;
  padding: 0;
  border: none;
  margin: 0;
  color: inherit;
  font-size: inherit;

  &.loading {
    padding-left: 30px;
  }

  .spinner {
    left: 5px;
    width: 20px;
    height: 20px;
  }
}

.loading {
  padding-left: 42px;
}
