@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;

.modal {
  min-height: 0;
}

.modalContent {
  padding: 25px 30px;
}

.modalTitles {
  position: relative;
  width: 100%;
  padding: 15px 0;
  text-align: center;
}

.title {
  margin: 0;
  margin-bottom: 20px;
}

.btnContainer {
  display: grid;
  justify-content: end;
  margin-top: 22px;
  gap: 22px;
  grid-template-columns: auto auto;
  text-align: center;
}

.btn {
  min-width: 100px;
  justify-content: center;
}

.input {
  border-color: black;
}
