@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;
@use 'sass:color';

$own-mobile-breakpoint: var.$mobile-breakpoint-m + 1;
$border-color: #ebebee;

.overlay {
  @media (max-width: $own-mobile-breakpoint) {
    overflow: hidden;
    max-height: 100vh;
    flex-direction: column;
  }
}

.modal {
  display: flex;
  max-width: 580px;
  min-height: 350px;
  max-height: 100%;
  flex-direction: column;

  @media (max-width: $own-mobile-breakpoint) {
    min-height: 250px;
  }
}

.modalContent {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px 20px 22px;
}

.dropzone {
  display: flex;
  height: 280px;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  @media (max-width: $own-mobile-breakpoint) {
    height: 240px;
    flex-grow: 0;
  }
}

.title {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid var.$border-color;
  margin: 0;
  margin-top: 20px;
  color: black;
  font-size: 18px;
  line-height: 1;
  text-align: left;
}

.smallText {
  margin-left: 4px;
  color: color.adjust(black, $lightness: 70%);
  font-size: 14px;
  text-align: center;
}

.separator {
  margin: 0 1px;
}

.filesContainer {
  position: relative;
  padding-right: 16px;
  margin-right: -8px;

  @include mix.perfect-scrollbar-size(5px, 10px);

  :global {
    .ps__rail-y {
      &:hover,
      &:focus,
      &.ps--clicking {
        background-color: #f1f1f1;
      }
    }
  }
}

.fileIcon {
  width: 35px;
  fill: #d6d6d6;
}

.file {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 15px 0;
  border-top: 1px solid $border-color;

  &:first-child {
    border: none;
  }
}

.filename {
  @include mix.text-overflow-ellipsis;
}

.middle {
  overflow: hidden;
  flex-grow: 1;
  margin-left: 20px;
}

.progressContainer {
  width: 100%;
  height: 4px;
  margin-top: 8px;
  background-color: $border-color;
  border-radius: 4px;
  visibility: hidden;
}

.progressContainerVisible {
  visibility: visible;
}

.progressBar {
  height: inherit;
  background-color: black;
  border-radius: inherit;
}

.progressBarUploading {
  transition: all 0.2s ease;
}

.buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 25px;
  border-top: 1px solid #ebebee;
}

.fileInfo {
  color: color.adjust(black, $lightness: 70%);
  font-size: 12px;
}

.error {
  font-size: 12px;
}

.fileAction {
  height: 30px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 1;

  & + & {
    margin-left: 12px;
  }
}

.actions {
  margin-left: 20px;
}

.fileActionRemove {
  width: 30px;
  padding: 0;
  padding-bottom: 2px;
  margin-right: 20px;
}

.fileActionUpload {
  height: 32px;
  padding-right: 10px;
  padding-left: 10px;
  margin-left: 25px;
  font-size: 14px;
}

.uploadAllIcon {
  width: 20px;
  margin-right: 8px;
}

.fileActionIcon {
  width: 20px;
  height: 20px;
}

.fileActionIconReload {
  transform: rotate(90deg) scaleY(-1);
}

.fileActionContainer {
  display: flex;
  width: 40px;
  height: 32px;
  align-items: center;
  justify-content: center;
  margin-left: 25px;
}

.failedFile {
  .filename {
    color: red;
  }
}

.check {
  width: 22px;
  height: 13px;
  border: 4px solid var.$success-color;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);
}
