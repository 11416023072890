@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;

.container {
  display: flex;
  max-width: 640px;
  align-items: center;
  justify-content: space-between;
  margin-right: auto;
  margin-left: auto;
}

.title {
  margin: 0;
  margin-bottom: 11px;
}

.description {
  color: #6b6f76;
  line-height: 1.3;
}

.textContent {
  max-width: 300px;
  margin-right: 20px;
}

.btn {
  min-width: 140px;
  height: 48px;
  justify-content: center;
  border-radius: 24px;
  font-size: 17px;
  font-weight: 600;
}
