@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;
@use 'sass:color';

.plan {
  display: flex;
  min-height: 330px;
  flex-direction: column;
  padding: 28px 25px;
  border: 1px solid rgb(26 26 26 / 0.1);
  border-radius: 10px;
  box-shadow: 0 4px 6px -2px rgb(16 24 40 / 0.03), 0 12px 16px -4px rgb(16 24 40 / 0.08);
}

.planAnimated {
  animation-delay: 0;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  animation-name: appear-up;
  animation-timing-function: ease;
  opacity: 0;

  @for $i from 1 through 4 {
    &.plan#{$i} {
      animation-delay: 0.1s * $i;
    }
  }
}

.paidPlanContainer {
  transition: transform 0.2s ease;

  &:not(.selectedPlanContainer):not(.disabledPlanContainer) {
    cursor: pointer;

    &:hover {
      transform: scale(1.03);
    }
  }
}

.planSelected {
  border: 1px solid var.$usc-gold-color;
  background-color: color.adjust(var.$usc-gold-color, $alpha: -0.85);
  cursor: no-drop;
}

.planDisabled {
  animation-name: appear-up-disabled;
  opacity: 0.5;

  &:hover {
    cursor: no-drop;
  }
}

.planName {
  font-size: 20px;
  font-weight: 700;
}

.planDescription {
  margin-top: 10px;
  color: rgb(26 26 26 / 0.5);
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 1.3;
}

.planPrice {
  display: flex;
  align-items: baseline;
  align-items: center;
  margin-top: auto;
  margin-left: auto;
}

.planPriceValue {
  display: flex;
  align-items: baseline;
  font-size: 30px;
}

.priceValueStrikethrough {
  position: relative;
  margin-top: 5px;
  margin-right: 5px;
  font-size: 20px;
  opacity: 0.5;

  &::after {
    $offset: 2px;
    position: absolute;
    top: 60%;
    left: -#{$offset};
    width: calc(100% + #{2 * $offset});
    height: 1px;
    background-color: currentColor;
    content: '';
  }

  .planPriceNumericValue {
    margin-right: 0;
  }
}

.planPriceNumericValue {
  margin-right: 5px;
  color: #1a1a1a;
  letter-spacing: -0.8px;
}

.planPriceCurrency {
  margin-right: 2px;
  font-size: smaller;
}

.planPricePeriod {
  color: rgb(26 26 26 / 0.5);
  font-size: 13px;
}

.planBtnContainer {
  margin-top: 20px;
}

.planBtn {
  width: 100%;
  height: 44px;
  justify-content: center;
  background-color: var.$main-color;
  color: #1a1a1a;
}

.planBadge {
  display: flex;
  align-self: flex-start;
  padding: 5px 8px;
  border: 1px solid rgb(26 26 26 / 0.1);
  margin-bottom: 15px;
  background-color: white;
  border-radius: 5px;
  font-size: 11px;
}

.planBadgeAnimated {
  animation: shimmer 2.5s infinite linear;
  background: linear-gradient(-45deg, var.$golden-yellow 40%, #fafafa 50%, var.$golden-yellow 60%);
  background-position-x: 100%;
  background-size: 300%;
  box-shadow: inset 0 0 0 2px rgb(255 224 102 / 0.95);
  color: #c08e11;
}

@keyframes shimmer {
  0%,
  35% {
    background-position-x: 100%;
  }

  65%,
  100% {
    background-position-x: 0%;
  }
}

@keyframes appear-up {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }

  30% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes appear-up-disabled {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }

  30% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
    transform: translateY(0);
  }
}

.hidden {
  display: none;
}
