@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;
@use 'sass:color';

.modal {
  display: flex;
  text-align: center;
}

.modalOverlay {
  backdrop-filter: none;
  background: transparent;
}

.title {
  margin: 0;
  color: #ff5402;
  font-size: 28px;
}

.description {
  margin-top: 25px;
  font-size: 15px;
  font-weight: 400;
  hyphens: initial;
  line-height: 1.4;
}

.btnContainer {
  display: grid;
  justify-content: center;
  margin-top: 30px;
  gap: 22px;
  grid-template-columns: auto auto;
  text-align: center;
}

.contentClassName {
  padding: 40px;
  margin: auto;
}

.ctaBtn {
  display: flex;
}

.btnEmoji {
  margin-top: 1px;
  margin-left: 5px;
}
