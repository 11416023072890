@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;

.btn {
  display: flex;
  width: 220px;
  height: 40px;
  align-items: center;
  padding: 0 14px;
  border: none;
  border: 1px solid black;
  appearance: none;
  background: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;

  &:active {
    transform: translate(1px, 1px);
  }
}

.dark {
  border-color: black;
  background-color: black;
  color: white;
}

.icon {
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-right: 12px;
  margin-bottom: 3px;
}

.text {
  margin: auto;
}
