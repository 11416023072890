@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;
@use 'sass:math';

$search-min-input-width: 210px;
$grid-item-h-to-w-ratio: math.div(100, 95);
var.$files-view-smaller-mobile-breakpoint: 465px;

:root {
  --ha-grid-item-width: 95px;
  --ha-grid-item-height: calc(var(--ha-grid-item-width) * #{$grid-item-h-to-w-ratio});
  --ha-grid-vertical-gap: 18px;
  --ha-grid-horizontal-gap: 60px;
  --ha-grid-items-per-row: 3;

  @media (max-width: var.$files-view-larger-mobile-breakpoint) {
    --ha-grid-item-width: 110px;
    --ha-grid-horizontal-gap: 45px;
  }

  @media (max-width: var.$files-view-smaller-mobile-breakpoint) {
    --ha-grid-horizontal-gap: 30px;
    --ha-grid-gaps-per-row: calc(var(--ha-grid-items-per-row) - 1);
    --ha-grid-item-width: calc(
      calc(
          100vw - #{var.$container-mobile-h-padding * 2} - var(--ha-grid-horizontal-gap) * var(--ha-grid-gaps-per-row)
        ) / var(--ha-grid-items-per-row)
    );
  }

  @media (max-width: 340px) {
    --ha-grid-items-per-row: 2;
  }
}

.container {
  display: flex;
  width: 100%;
  max-width: var.$container-width;
  flex-direction: column;
  flex-grow: 1;
  padding: 15px 0 50px;
  margin: 0 auto;

  @media (max-width: var.$container-width + 30px) {
    padding-right: var.$container-mobile-h-padding;
    padding-bottom: 80px;
    padding-left: var.$container-mobile-h-padding;
  }

  @media (max-width: var.$files-view-smaller-mobile-breakpoint) {
    padding-top: 30px;
  }
}

.gridContainer {
  position: relative;
  margin-top: 50px;

  @media (max-width: var.$files-view-smaller-mobile-breakpoint) {
    margin-top: 20px;
  }
}

.filesGrid {
  display: grid;

  // overrides the inline styles of the InfiniteScroll component
  overflow: visible !important;
  justify-content: space-between;
  column-gap: var(--ha-grid-horizontal-gap);
  grid-template-columns: repeat(auto-fill, var(--ha-grid-item-width));
  row-gap: var(--ha-grid-vertical-gap);
}

.gridItem {
  position: relative;
  margin-bottom: var(--ha-grid-vertical-gap);
}

.gridItemDate {
  margin: 0;
  margin-bottom: 10px;
  color: #b6b6b6;
  font-size: 15px;
  grid-column: 1 / -1;
  text-align: center;
}

.pushLeft {
  margin-left: auto;
}

h1 {
  font-size: 30px;
}

.inputOuterContainerHidden {
  opacity: 0;
  visibility: hidden;
}

.inputOuterContainer {
  display: flex;
  align-items: center;
  padding-left: 7px;
  margin-left: auto;

  @media (max-width: var.$files-view-smaller-mobile-breakpoint) {
    margin-left: 0;
  }

  &:focus-within,
  &:focus {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }
}

.inputOuterContainerLeft {
  display: flex;
  overflow: hidden;
  width: 100%;
  align-items: center;
  padding-left: 7px;
  margin-right: auto;

  @media (max-width: var.$files-view-smaller-mobile-breakpoint) {
    padding-left: 0;
    margin-left: 0;
  }
}

.inputContainer {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.spinner {
  color: var.$main-color;
}

.spinnerBig {
  width: 50px;
  height: 50px;
}

.searchInput {
  min-width: $search-min-input-width;
  border: none;
  background: none;
  box-shadow: none;

  input {
    padding-left: 7px;
  }
}

.searchIcon {
  width: 15px;
  height: auto;
}

.centerContainer {
  position: relative;
  display: flex;
  flex-flow: column wrap;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 80px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;

  @media (max-width: var.$desktop-breakpoint) {
    font-size: 18px;
  }
}

.or {
  margin-top: 20px;

  @media (max-width: var.$desktop-breakpoint) {
    margin-top: 12px;
  }
}

* + .button {
  margin-top: 25px;

  @media (max-width: var.$desktop-breakpoint) {
    margin-top: 15px;
  }
}

.afterFiles {
  grid-column: 1/-1;
  transition: all 0.2s ease;
}

.infiniteScrollLoader {
  top: calc(100% + 20px);
  left: 0;
  display: flex;
  overflow: hidden;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.endMessage {
  color: #6e6e6e;
  font-weight: 600;
  letter-spacing: 0.25px;
  text-align: center;
}

.infiniteScrollSpinner {
  width: 25px;
  height: 25px;
}

.navTitle {
  position: relative;

  @include mix.text-overflow-ellipsis;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: var.$container-width + 60) {
    margin-bottom: 10px;
  }
}

.goBack {
  margin-right: 10px;
  font-size: 0;
}

.goBackIcon {
  width: 30px;
  height: 30px;

  @media (max-width: var.$container-width + 60) {
    position: static;
    margin-right: 10px;
    transform: none;
  }
}

.hashtagNotFound {
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 400;
}

.secondaryNotFoundBtn {
  background-color: black;
}

.blackBtn {
  border: none;
  margin-top: 20px;
  background: black;
  border-radius: 20px;
  box-shadow: none;

  &:hover::before,
  &::before {
    content: none;
  }
}

.noFilesUploadToggle {
  width: 100px;
  justify-content: center;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 400;

  [class*='_menu_'] {
    @media (max-width: var.$mobile-breakpoint-m) {
      top: calc(100% + 10px);
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.selectionArea {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  user-select: none;
}
