@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;

.container {
  display: flex;
  justify-content: flex-end;

  @media (max-width: var.$desktop-breakpoint) {
    width: 100%;
    justify-content: flex-start;
    padding: 10px 15px;
    border-top: 1px solid #ccc;
    margin: 0;
    background: white;
  }
}

.profileLink {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.profileLinkIcon {
  width: 28px;
  height: 28px;
}

.username {
  margin-left: 8px;
  font-weight: 600;
}

.profileLayer {
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  width: 320px;
  min-height: 150px;
  flex-direction: column;
  padding: 45px 19px 30px;
  background-color: whitesmoke;
  box-shadow: -1px 0 15px 0 rgb(0 0 0 / 0.12);
  opacity: 0;
  pointer-events: none;
  transform: translateX(30px);
  transition: all 0.2s ease;
  visibility: hidden;

  @media (max-width: var.$mobile-breakpoint-m) {
    top: 50px;
    left: 0;
    width: 100%;
    border-radius: 8px 8px 0 0;
    transform: translateY(100%);
    transition: all 0.35s ease;
  }
}

.profileLayerInner {
  opacity: 1;
  transform: translateX(100%);
  transition-duration: 0.35s;

  @media (max-width: var.$mobile-breakpoint-m) {
    top: 50px;
    left: 0;
    width: 100%;
    border-radius: 8px 8px 0 0;
    transform: translateY(100%);
    transition: all 0.35s ease;
  }

  &.profileLayerOpened {
    transition-duration: 0.35s;
  }
}

.profileLayerOpened {
  opacity: 1;
  pointer-events: initial;
  transform: translate(0);
  visibility: visible;
}

.navIcon {
  position: absolute;
  top: 0;
  left: 5px;
  width: 30px;
  width: 25px;
  box-sizing: content-box;
  padding: 12px;
  cursor: pointer;

  @media (max-width: var.$mobile-breakpoint-m) {
    top: 5px;
  }
}

.close {
  right: 5px;
  left: auto;
  width: auto;
  font-size: 30px;
  font-weight: 300;
  line-height: 18px;
}

.section {
  width: 100%;

  & + & {
    margin-top: 30px;
  }
}

.sectionTitle {
  margin: 0;
  margin-bottom: 10px;
  margin-left: 15px;
  color: #aeaeae;
  font-size: 13px;
  font-weight: 320;
  text-transform: uppercase;
}

.button {
  width: 100%;
  justify-content: center;
}

.planStorage {
  width: 100%;
}

.planStorageText {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.planStorageIcon {
  width: 22px;
  height: 22px;
  margin-right: 15px;
  color: var.$main-color;

  &,
  path {
    fill: currentColor;
  }
}

.planStorageProgressBar {
  width: 100%;
  height: 4px;
  margin-top: 5px;
  background-color: #ededed;
  border-radius: 2px;
}

.planStorageProgressBarProgress {
  height: inherit;
  background-color: var.$main-color;
  border-radius: inherit;
}

.overlay {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-backdrop-filter: blur(5px);
  background-color: rgb(9 62 91 / 0.3);
  opacity: 0;
  transition: all 0.3s ease;
  visibility: hidden;
}

.overlayOpened {
  opacity: 1;
  visibility: visible;
}

.profileLayerTitle {
  margin: 0;
  margin-bottom: 25px;
  font-size: 25px;
  font-weight: 600;
}

.panel {
  overflow: hidden;
  background: white;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
}

$hover-color: #e8e8e8;

.panelItem {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px 15px;

  &:global(.clickable):hover {
    background-color: $hover-color;
  }

  & + & {
    &::before {
      position: absolute;
      top: 0;
      right: 0;
      left: 52px;
      border-top: 1px solid #d2d2d2;
      content: '';
    }
  }
}

.panelItemDisabled {
  opacity: 0.5;

  &.panelItemDisabled {
    cursor: not-allowed;
  }
}

a.panelItem:hover {
  background-color: $hover-color;
}

.footer {
  align-self: center;
  margin-top: auto;
  font-size: 14px;
}

.link {
  border-bottom: 1px solid currentColor;
}

.logoContainer {
  margin-bottom: 30px;
  text-align: center;
}

.hasharelogo {
  max-width: 150px;
}

.panelIcon {
  width: 22px;
  height: 22px;
  margin-right: 15px;
  color: var.$main-color;

  &,
  path {
    fill: currentColor;
  }
}

.panelIconDanger {
  color: red;
}

.panelIconStroke {
  &,
  path {
    fill: none;
    stroke: currentColor;
  }
}

.panelItemChevron {
  width: 10px;
  height: 10px;
  margin-left: auto;
  color: #ccc;

  &,
  path {
    fill: none;
    stroke: currentColor;
  }
}

.modal {
  min-height: 0;
}

.modalTitle {
  margin: 0;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 1.3;
}

.btnContainer {
  display: grid;
  justify-content: end;
  margin-top: 22px;
  gap: 22px;
  grid-template-columns: auto auto;
  text-align: center;
}

.btn {
  min-width: 100px;
  justify-content: center;
}

.input {
  border-color: black;
}
