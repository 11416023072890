@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;

.modal {
  max-width: 490px;
  min-height: 0;
}

.title {
  margin-top: 0;
  margin-bottom: 30px;
  text-align: center;
}

.btnContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.btn + .btn {
  margin-top: 20px;
}

.input {
  border-color: black;
}

.modalContent {
  padding: 35px 25px 40px;
}
