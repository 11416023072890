@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;

.hashtagBtn {
  min-width: 170px;
  height: 50px;
  padding-right: 15px;
  padding-left: 13px;
  background-color: var.$main-color;
  border-radius: 8px;
  font-size: 18px;
}

.modal {
  min-height: 0;
}

.modalTitle {
  margin: 0;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 1.3;
}

.btnContainer {
  display: grid;
  justify-content: end;
  margin-top: 22px;
  gap: 22px;
  grid-template-columns: auto auto;
  text-align: center;
}

.input {
  border-color: black;
}

.icon {
  width: 16px;
  margin-right: 8px;
}
