@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;
@use 'sass:color';

$smaller-mobile-breakpoint: 465px;
$context-menu-overlay-z-index: var.$navbar-z-index + 1;
$context-menu-z-index: $context-menu-overlay-z-index + 1;

.file {
  position: relative;
  font-family: Helvetica, sans-serif;
  font-weight: 600;
  transition: all 0.2s ease;

  &::before {
    $vertical-margin: 9px;
    $horizontal-margin: 9px;
    position: absolute;
    z-index: -1;
    top: -$vertical-margin;
    left: -$horizontal-margin;
    display: block;
    width: calc(100% + 2 * $horizontal-margin);
    height: calc(100% + 2 * $vertical-margin + 2px);
    background-color: #ececec;
    border-radius: 11px;
    content: '';
    opacity: 0;
    transition: opacity 0.3s ease, visibiliy 0.3s ease;
    visibility: hidden;
  }
}

.fileIcon {
  position: relative;
  display: flex;
  height: var(--ha-grid-item-height);
  align-items: flex-end;
  justify-content: flex-end;
  padding: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(255 214 10);
  background: rgb(255 214 10);
  border-radius: 20px;
}

.folderIcon {
  position: relative;
  display: flex;
  height: var(--ha-grid-item-height);
  align-items: flex-end;
  justify-content: flex-end;
  background: rgb(255 214 10);
  background: url(folder.svg) center center no-repeat;
  border-radius: 0;
}

.fileType {
  right: 0;
  bottom: 0;
  color: white;
  font-size: 20px;
  text-align: right;
  text-transform: lowercase;

  @media (max-width: $smaller-mobile-breakpoint) {
    font-size: 16px;
  }
}

.fileNavigationContainer {
  display: contents;
  cursor: pointer;
}

.fileName {
  display: -webkit-box;
  overflow: hidden;
  max-height: 60px;
  padding: 0 7px;
  margin-top: 15px;
  -webkit-box-orient: vertical;
  color: #535353;
  font-size: 12px;
  -webkit-line-clamp: 3;
  line-height: 20px;
  text-align: center;

  @media (max-width: $smaller-mobile-breakpoint) {
    padding: 0 5px;
  }
}

.fileUsername {
  padding: 0 7px;
  margin-top: 8px;
  color: #b6b6b6;
  font-size: 14px;
  font-weight: 400;
  text-align: center;

  @media (max-width: $smaller-mobile-breakpoint) {
    padding: 0 5px;
  }
}

.fileName:not(.fileNameMobile) {
  word-break: break-word;
}

$menu-in-transition-duration: 0.25s;

.contextMenuContainer {
  position: absolute;
  z-index: 1;
  top: calc(100% + 30px);
  left: 50%;
  width: 150px;
  padding-bottom: 30px;
  cursor: default;
  opacity: 0;
  pointer-events: none;
  transform: translateX(-50%);
  transition: opacity $menu-in-transition-duration ease,
    visibility $menu-in-transition-duration ease;
  visibility: hidden;

  &.overflows {
    transition: none;
  }

  &.left {
    right: auto;
    left: -10px;
    transform: none;
  }

  &.right {
    right: -10px;
    left: auto;
    transform: none;
  }

  &.bottom {
    top: auto;
    bottom: calc(100%);
  }
}

.contextMenuContainerOpen {
  opacity: 1;
  visibility: visible;
}

.contextMenu {
  overflow: hidden;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 0.05), 0 11px 14px -7px rgb(0 0 0 / 0.1);
  pointer-events: initial;
}

.contextMenuItem {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px 0 14px;
  border-top: 1px solid #d9d9d9;
  cursor: pointer;
  font-weight: 400;

  &:hover {
    background-color: whitesmoke;
  }

  &:first-child {
    border-top: none;
  }
}

.delete {
  color: red;
}

.contextMenuItemIcon {
  width: 20px;
  height: 20px;
}

.contextMenuOverlay {
  position: fixed;
  z-index: $context-menu-overlay-z-index;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(5px);
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-backdrop-filter: blur(5px);
  background-color: rgb(9 62 91 / 0.3);
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s ease;
  visibility: hidden;
}

.contextMenuOverlayVisible {
  opacity: 1;
  pointer-events: initial;
  visibility: visible;
}

.gridItemContextMenu {
  z-index: $context-menu-z-index;
  padding-bottom: calc(2 * var(--ha-grid-vertical-gap));
  margin-bottom: calc(-1 * var(--ha-grid-vertical-gap));

  .file {
    transform: scale(1.05);

    &::before {
      opacity: 1;
      visibility: visible;
    }
  }
}

.actions {
  position: absolute;
  z-index: 1;
  top: 6px;
  right: 6px;
  margin: 0;
  color: white;
  opacity: 0;
  transition: all 0.2s ease;
  visibility: hidden;

  .folderIcon & {
    top: 25px;
  }

  &.actionsMobile {
    display: none;
  }

  .gridItemContextMenu &,
  .file:hover & {
    opacity: 1;
    visibility: visible;
  }
}

.action {
  padding: 0 2px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.2s ease;

  &:hover {
    background-color: rgb(255 255 255 / 0.35);
  }

  &:active {
    transform: translate(1px, 1px);
  }
}

.actionIcon {
  width: 20px;
  height: auto;
  pointer-events: none;

  &,
  path {
    fill: currentColor;
  }
}

.fileSelected {
  transform: scale(1.03);

  &::before {
    opacity: 1;
    visibility: visible;
  }
}
