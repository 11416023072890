@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;

.title {
  margin: 0;
  margin-bottom: 20px;
  font-size: 36px;
  font-weight: 700;
  hyphens: initial;
}

.faqQuestion {
  font-size: 16px;
  font-weight: 700;
}

.faqAnswer {
  margin-top: 10px;
  color: #6b6f76;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
}

.faqEntry {
  & + & {
    margin-top: 30px;
  }
}

.container {
  & + * {
    padding-top: 70px;
    border-top: 1px solid var.$border-color;
    margin-top: 20px;
  }
}
