@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;
@use 'sass:color';

$grey: #b8b8b8;

.dropzone {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  border: 2px dashed color.adjust(var.$border-color, $lightness: -10%);
  border-radius: 10px;
}

.dropzoneActive {
  border-style: solid;
}

.text {
  font-size: 18px;
  text-align: center;

  & + & {
    margin-top: 12px;
  }
}

.smallText {
  margin: 17px 0;
  color: color.adjust(black, $lightness: 70%);
  text-align: center;
}

.overlayIcon {
  &,
  path {
    fill: currentColor;
  }
}

.overlayText {
  margin-top: 14px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
}
