@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;
@use 'sass:color';

$background-color: #ccc;

.container {
  position: relative;
  cursor: pointer;

  &.active,
  &:hover {
    .iconContainer {
      border-radius: 50%;
      transform: rotate(180deg) scale(0.75);
    }
  }

  &:active {
    .iconContainer {
      transform: rotate(180deg) scale(0.5);
    }
  }
}

.iconContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  aspect-ratio: 1/1;
  background-color: $background-color;
  border-radius: 20px;
  transform-origin: 50% 50%;
  transition: all 0.3s ease;
}

.icon {
  width: 28px;
  height: 28px;
  color: currentColor;

  &,
  path {
    stroke: currentColor;
  }
}

.menu {
  position: absolute;
  z-index: 1;
  top: 0;
  left: calc(100% + 10px);
  overflow: hidden;
  min-width: 200px;
  border: 2px solid black;
  background: white;
  border-radius: 10px;
  opacity: 0;
  transition: all 0.1s ease;
  visibility: hidden;
  white-space: nowrap;
}

.menuOpened {
  opacity: 1;
  visibility: visible;
}

.menuItem {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-top: 1px solid var.$border-color;

  &:hover {
    background-color: color.adjust($background-color, $lightness: 15%);
  }

  &:first-child {
    border-top: none;
  }
}

.menuItemIcon {
  width: 20px;
  height: 20px;
}

* + .menuItemText {
  margin-left: 10px;
}
