@use 'src/styles/scss_variables' as var;
@use 'sass:math';

@mixin input-box-styles {
  border: 1px solid var.$border-color;
  border-radius: 5px;
}

@mixin input-text-styles {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
}

@mixin object-fit-cover {
  width: 100%;
  max-width: none;
  height: 100%;
  max-height: none;
  object-fit: cover;
}

@mixin absolute-inset($top: 0, $right: $top, $bottom: $top, $left: $top) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin base-font-styles {
  color: var.$primary-text-color;
  font-family: 'Helvetica Neue', -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  letter-spacing: 0;
  word-break: break-word;
}

@mixin root-element-styles {
  position: relative;
  margin: 0;
  hyphens: auto;

  @include base-font-styles;

  * {
    box-sizing: border-box;
    text-size-adjust: none;
  }
}

@mixin base-styles {
  button,
  input,
  textarea {
    font-family: inherit;
    outline: none;
  }

  img,
  video {
    max-width: 100%;
  }

  b {
    font-weight: 500;
  }

  em,
  i {
    font-style: italic;
  }

  svg {
    fill: currentColor;
    vertical-align: top;
  }

  a {
    color: var(--hashare-primary-color);
    text-decoration: none;
  }

  a.mailto {
    color: inherit;
    font-weight: 500;
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
    font-family: inherit;
    font-weight: 400;
    line-height: normal;
  }
}

@mixin box-shadow-with-alpha($box-shadow, $alpha: 1) {
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    box-shadow: $box-shadow;
    content: '';
    opacity: $alpha;
    transition: all 0.2s ease;
  }
}

@mixin background-with-alpha($color, $alpha: 1) {
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color;
    border-radius: inherit;
    content: '';
    opacity: $alpha;
    transition: all 0.2s ease;
  }
}

@mixin perfect-scrollbar-size($rail-thumb-size: 3px, $rail-width: 6px) {
  :global {
    .ps__thumb-y {
      right: $rail-thumb-size;
      left: math.div($rail-width - $rail-thumb-size, 2);
      width: $rail-thumb-size;
    }

    .ps__rail-y {
      width: $rail-width;

      &:hover > .ps__thumb-y {
        width: $rail-thumb-size;
      }

      &.ps--clicking .ps__thumb-y {
        width: $rail-thumb-size;
      }
    }
  }
}
