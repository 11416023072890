@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;

.container {
  width: 100%;
  max-width: var.$container-width;
  padding: 40px 0 80px;
  margin: 0 auto;

  @media (max-width: 660px) {
    padding-right: 25px;
    padding-left: 25px;
  }
}

.titles {
  margin: 70px 0 75px;
  hyphens: initial;
  text-align: center;

  @media (max-width: var.$tablet-breakpoint) {
    margin: 50px 0 55px;
  }

  @media (max-width: var.$mobile-breakpoint-m) {
    margin: 30px 0 35px;
  }
}

.title {
  margin: 0;
  margin-bottom: 15px;
  font-size: 56px;
  font-weight: 700;

  span {
    color: var.$main-color;
  }

  @media (max-width: var.$tablet-breakpoint) {
    font-size: 36px;
  }
}

.subtitle {
  margin: 0;
  color: #6b6f76;
  font-size: 20px;
}

.form {
  max-width: 600px;
  padding: 40px 35px 35px;
  margin: 0 auto;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 6px 13px 1px rgb(0 0 0 / 0.1);
  text-align: left;
  transition: transform 0.5s ease;

  @media (max-width: 660px) {
    max-width: none;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    margin: 0;
    box-shadow: none;
  }
}

.input {
  & + & {
    margin-top: 25px;
  }
}

.inputInner {
  min-height: 50px;
  border: none;

  input {
    height: 50px;
  }

  textarea,
  input {
    padding: 0;
    padding: 5px 10px;
    border: 1px solid #ddd;
    background-color: #fcfcfc;
    border-radius: 8px;
    caret-color: black;
    font-weight: 400;
    transition: border-color 0.3s, box-shadow 0.3s;

    &[disabled] {
      color: var.$primary-text-color;
    }

    &:focus {
      border-color: black;
      background-color: white;
      outline: none;
    }

    &:not(:placeholder-shown) {
      background-color: white;
      outline: none;
    }
  }

  textarea {
    min-height: 200px;
    max-height: 500px;
    padding: 10px;
  }

  ::placeholder {
    color: black;
  }
}

.hasError {
  textarea,
  input {
    &,
    &:focus {
      border: 1px solid red;
    }
  }
}

.textareaInner.textareaInner {
  border: none;
}

.submitBtn {
  min-width: 150px;
  height: 50px;
  justify-content: center;
  padding: 15px;
  border: none;
  background-color: #ffd60aff;
  border-radius: 8px;
  color: black;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ffc107;
    color: black;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.inputLabel {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 400;

  span {
    color: #939393;
    font-size: smaller;
    font-style: normal;
  }
}
