@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;

.container {
  z-index: var.$navbar-z-index;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 35px 15px 25px 45px;
  background-color: white;

  @media (max-width: var.$desktop-breakpoint + 1) {
    position: fixed;
    top: 0;
    padding-top: 10px;
    padding-bottom: 8px;
    box-shadow: 0 5px 8px 1px rgba(255 255 255 / 0.8);
  }

  @media (max-width: var.$mobile-breakpoint-m) {
    padding-right: 0;
  }
}

.menu {
  display: flex;
  align-items: center;
  margin-right: 18px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 17px;

  > a + a {
    margin-left: 30px;
  }

  @media (max-width: var.$desktop-breakpoint + 1) {
    position: absolute;
    top: var.$mobile-header-height;
    left: 0;
    display: none;
    width: 100%;
    flex-direction: column;
    padding: 8px 10px;
    background: white;
    box-shadow: 0 8px 16px hsl(0deg 0% 4% / 0.1);

    > a {
      width: 100%;
      padding: 8px 12px;
      line-height: 1.45;
      text-align: left;
    }

    > a + a {
      margin-left: 0;
    }
  }
}

.menuAuthorized {
  @media (max-width: var.$desktop-breakpoint + 1) {
    padding-bottom: 0;
  }
}

.menuVisible {
  display: flex;
}

.logoLink {
  display: inline-flex;
  height: 100%;
  align-items: center;
}

.hasharelogo {
  top: 0;
  left: 0;
  width: 220px;

  @media (max-width: 576px) {
    .hasharelogo {
      display: none;
    }
  }
}

.menuToggle {
  display: flex;
  display: none;
  width: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: var.$desktop-breakpoint + 1) {
    display: flex;
  }
}

.menuToggleLine {
  position: relative;
  width: 16px;
  height: 1px;
  background-color: black;
  pointer-events: none;

  &::before,
  &::after {
    position: absolute;
    display: block;
    width: inherit;
    height: inherit;
    background-color: black;
    content: '';
    transform-origin: 50% 50%;
  }

  &::before {
    top: -6px;
  }

  &::after {
    top: 6px;
  }
}

.menuToggleOpened {
  .menuToggleLine {
    width: 20px;
    background: none;

    &::before,
    &::after {
      top: 0;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}

.signInBtn {
  margin-right: 35px;

  @media (max-width: var.$desktop-breakpoint + 1) {
    order: 1;
    margin-top: 10px;
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.userMenu {
  order: 2;
  margin-left: 35px;

  @media (max-width: var.$desktop-breakpoint + 1) {
    margin-left: 0;
  }
}
