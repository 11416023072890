@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;

.modal {
  min-height: 0;
}

.modalContent {
  display: flex;
  min-height: 170px;
  flex-direction: column;
  justify-content: center;
}

.title {
  padding: 0 20px;
  margin: 0;
  line-height: 1.4;
  text-align: center;
}

.btnContainer {
  display: grid;
  justify-content: center;
  margin-top: 25px;
  gap: 22px;
  grid-template-columns: auto auto;
  text-align: center;
}

.btn {
  min-width: 100px;
  justify-content: center;
}
