@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;

.textarea {
  position: relative;

  @include mix.input-box-styles;
}

.label:not(:empty) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 9px;
}

.textareaTextarea {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  min-height: 121px;
  max-height: 300px;
  padding: 10px 12px 11px;
  border: none;
  background: none;
  caret-color: black;
  resize: vertical;

  &::placeholder {
    color: #ccc;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0;
  }

  @include mix.input-text-styles;
}

.labelRight {
  font-style: italic;
}

.hasError {
  .input {
    border-color: red;
  }
}

.error {
  padding: 0 6px;
  margin-top: 8px;
  color: red;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
}
