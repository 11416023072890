@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;

:where(.spinner) {
  position: relative;
  width: 24px;
  height: 24px;
  animation: spin 0.5s infinite linear;
}

.spinner::before,
.spinner::after {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 4px solid currentColor;
  border-radius: 50%;
  content: '';
  font-size: 0;
  opacity: 0.3;
  transform: translateZ(0);
}

.spinner::after {
  z-index: 1;
  border-color: transparent;
  border-left-color: currentColor;
  opacity: 1;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
