@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;
@use 'sass:color';

.modalOverlay {
  @include mix.absolute-inset(0);
  position: fixed;
  z-index: var.$modal-overlay-z-index;
  display: flex;
  overflow: auto;
  align-content: flex-start;
  align-items: flex-start;
  padding: 27px;
  backdrop-filter: blur(5px);
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-backdrop-filter: blur(5px);
  background-color: rgb(9 62 91 / 0.3);
  opacity: 0;
  transition: all 0.2s ease;

  @media (max-width: var.$mobile-breakpoint-m) {
    padding: 20px 10px;
  }
}

.modalOverlayAfterOpen {
  opacity: 1;
}

.modalOverlayBeforeClose {
  opacity: 0;
}

.modal {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 460px;
  min-height: 250px;
  margin: auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 3px 8px 2px rgb(0 0 0 / 0.15);
  outline: none;
}

.modalContent {
  padding: 25px 30px;
  font-size: 14px;
  line-height: 20px;
}

.children {
  width: 100%;
}

.modalClose {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  display: flex;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  margin-top: 7px;
  margin-right: 5px;
  border-radius: 50%;
  cursor: pointer;

  @media (max-width: var.$tablet-breakpoint) {
    margin: 12px;
  }
}

.modalCloseIcon {
  width: 11px;
  height: auto;
  color: #afafaf;

  path {
    fill: currentColor;
  }
}
