@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;

:where(body) {
  width: 100%;
}

:where(html:not(.hashare-embedded)) {
  @include mix.base-styles;
  width: 100vw;
  min-height: var(--ha-vh100);
  margin-right: calc(-1 * (100vw - 100%));
  overflow-x: hidden;

  @media (max-width: var.$container-width + 30px) {
    width: 100%;
    overflow-x: initial;
  }

  body {
    @include mix.root-element-styles;
    width: 100%;
    min-height: var(--ha-vh100);
  }
}

:where(.hashare-embedded .hashare-root) {
  width: 100%;
  min-height: min(var(--ha-embedded-min-height), var(--ha-vh100));

  @include mix.root-element-styles;
  @include mix.base-styles;
}

:where(.hashare-embedded .hashare-form-portal) {
  @include mix.root-element-styles;
  @include mix.base-styles;
}

.overflowHidden {
  overflow: hidden;
}

.clickable {
  cursor: pointer;
}

.selection-area {
  border: 2px solid rgb(98 155 255 / 0.81);
  background: rgb(46 115 252 / 0.11);
  border-radius: 0.1em;
}

.hidden {
  display: none;
}

@keyframes wiggle {
  0%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-3px);
  }

  75% {
    transform: translateX(3px);
  }
}

.wiggle-animation {
  animation-name: wiggle;
  animation-timing-function: ease-out;
}
