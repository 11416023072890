@use 'sass:color';
@use 'src/styles/mixins' as mix;
@use 'src/styles/scss_variables' as var;

.progress {
  position: fixed;
  z-index: var.$progress-bar-z-index;
  top: 0;
  left: 0;
  width: 100%;
  height: 7px;
  background-color: white;
  border-radius: 10px;
  opacity: 0;
  transition: all 0.3s ease;
  transition-delay: 0.7s;
  visibility: hidden;
}

.progressBar {
  width: 0;
  height: 100%;
  background: linear-gradient(
    to right,
    color.adjust(var.$main-color, $whiteness: 15%) 00%,
    var.$main-color 50%
  );
  background-color: var.$main-color;
  transition: width 0.15s ease;
}

.progressVisible {
  opacity: 1;
  transition-delay: 0s;
  visibility: visible;
}
