@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;

.title {
  margin-top: 0;
  margin-bottom: 8px;
}

.subtitle {
  margin-top: 0;
  margin-bottom: 36px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 1.3;
  opacity: 0.85;
  text-align: center;
}

.btnContainer {
  display: grid;
  justify-content: end;
  margin-top: 22px;
  gap: 22px;
  grid-template-columns: auto auto;
  text-align: center;
}

.input {
  border-color: black;
}

.requirementsContainer {
  display: flex;
  width: 100%;
}

.requirements {
  justify-content: center;
  padding: 0;
  margin: 5px 0 20px 20px;
  line-height: 1.6;
  list-style-type: square;
}

.btn {
  min-width: 100px;
  justify-content: center;
}

.createHashtagBtn {
  min-width: 170px;
  height: 50px;
  padding-right: 15px;
  padding-left: 13px;
  background-color: var.$main-color;
  border-radius: 8px;
  font-size: 18px;
}

.btnIconPlus {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 11px;

  &::before,
  &::after {
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    width: 100%;
    height: 2px;
    background-color: currentColor;
    content: '';
  }

  &::after {
    transform: rotate(90deg);
  }
}
