@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;

.inputInput {
  position: relative;
}

.icons {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  background-color: white;
  border-radius: inherit;
  cursor: pointer;
}

.icon {
  width: 20px;
  height: 20px;

  &,
  path {
    fill: currentColor;
  }
}

.iconClosed {
  transform: translateY(2px);
}
