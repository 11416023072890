@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;

.containerOuter {
  display: flex;
  justify-content: center;
}

.container {
  display: flex;
  padding: 2px 2px 3px;
  border: 1px solid rgb(26 26 26 / 0.15);
  margin: 0 auto;
  border-radius: 6px;
  font-size: 14px;
  text-align: center;
}

.containerInner {
  position: relative;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
}

%pill {
  position: relative;
  z-index: 2;
  min-width: 115px;
  height: auto;
  padding: 8px 32px;
  border-radius: 6px;
  line-height: 20px;
  white-space: nowrap;
}

.option {
  @extend %pill;
  color: #1a1a1a;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.2s ease;

  &:not(.optionSelected):hover {
    opacity: 0.7;
  }
}

.optionSelected {
  opacity: 1;
}

.switch {
  @extend %pill;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 50%;
  height: auto;
  height: 100%;
  border: 1px solid rgb(26 26 26 / 0.1);
  background-color: var.$main-color;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 0.07), 0 1px 1.5px 0 rgb(0 0 0 / 0.05);
  pointer-events: none;
  transition: all 0.2s ease;
}

.switchAlternated {
  left: 50%;
}
