@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;

.dropzone {
  display: contents;
}

.overlay {
  @include mix.absolute-inset(0);
  position: fixed;
  z-index: var.$navbar-z-index + 1;
  display: none;
  align-items: center;
  justify-content: center;

  .overlayContent {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: rgb(255 255 255 / 0.8);
    font-size: 26px;
    font-weight: 500;
  }
}

.overlayVisible {
  display: flex;
}

.overlayContent {
  padding: 0 20px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.18px;
  line-height: 1.4;
  text-align: center;
}

.overlayIcon {
  &,
  path {
    fill: currentColor;
  }
}
