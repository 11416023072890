@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;
@use 'sass:color';

.iconContainer {
  display: inline-flex;
  padding: 2px;
  margin-left: 6px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
}

.icon {
  $size: 16px;
  width: $size;
  height: $size;
  color: #323232;
}

.modal {
  min-height: 0;
}

.modalContent {
  display: flex;
  min-height: 170px;
  flex-direction: column;
  justify-content: center;
}

.title {
  margin: 0;
  text-align: center;
}

.btnContainer {
  display: grid;
  justify-content: center;
  margin-top: 25px;
  gap: 22px;
  grid-template-columns: auto auto;
  text-align: center;
}

.btn {
  min-width: 100px;
  justify-content: center;
}
