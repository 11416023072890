@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;

.breadcrumbLast {
  flex-shrink: 0;

  .breadcrumbText {
    opacity: 0.5;
    transition: opacity 0.2s ease;
  }
}

.breadcrumbsDelimiter {
  display: inline-block;
  flex-shrink: 0;
  margin-right: 1px;
  margin-left: 3px;
  font-size: 22px;
  line-height: 1;
}

.hashtagBreadcrumbsDelimiter {
  margin: 0 1px 0 5px;
}

.breadcrumb {
  @include mix.text-overflow-ellipsis;
  max-width: 100%;
  font-size: 18px;
  line-height: 1.5;

  &:hover {
    .breadcrumbText {
      opacity: 0.7;
    }
  }
}

.breadcrumbsDelimiter,
.breadcrumbText {
  vertical-align: middle;
}

.breadcrumbText {
  padding: 0 2px;
}
