@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;
@use 'sass:color';
@use 'sass:math';

$light-text-color: #7f8082;
$regular-text-color: #282a30;
$border-color: color.change(#929cad, $alpha: 0.3);

.footer {
  margin-top: auto;
  font-family: 'Helvetica Neue', -apple-system, sans-serif;
  font-size: 14px;
  hyphens: initial;
  line-height: 1.5;

  &::before {
    display: block;
    height: 0;
    border-top: 1px solid $border-color;
    margin-right: 40px;
    margin-left: 30px;
    content: '';
  }

  p {
    padding: 4px 0;
    margin: 0;
    color: $light-text-color;

    &:empty {
      padding: 0;
    }
  }

  a {
    box-shadow: inset 0 -1px 0 0 white, inset 0 -2px 0 0 currentColor;
  }

  &.dark {
    background-color: black;
    color: white;

    p {
      color: color.adjust($light-text-color, $lightness: 17%);
    }

    a {
      box-shadow: inset 0 -1px 0 0 black, inset 0 -2px 0 0 currentColor;
    }
  }
}

.footerInner {
  display: grid;
  max-width: 1100px;
  padding: 66px 30px 100px;
  margin: 0 auto;
  column-gap: 30px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: var.$tablet-breakpoint - 1) {
    display: block;
  }
}

.footerLogo {
  height: 35px;
  margin-bottom: 18px;
}

.footerColumnTitle {
  padding: 4px 0;
  margin: 0;
  color: inherit;
  font-size: 16px;
}

.footerColumn {
  @media (max-width: var.$tablet-breakpoint - 1) {
    & + & {
      margin-top: 30px;
    }
  }
}
