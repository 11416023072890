@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;

.spinner {
  width: 60px;
  height: 60px;
  animation-duration: 0.5s;
  color: var.$main-color;
}

.spinnerContainer {
  display: flex;
  min-height: calc(100vh - var.$header-height);
  flex-flow: column wrap;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 80px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;

  @media (max-width: var.$desktop-breakpoint + 1) {
    min-height: calc(100vh - var.$mobile-header-height);
  }
}
