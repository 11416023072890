@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;
@use 'sass:color';

.container {
  width: 100%;
  max-width: 700px;
  padding: 4px 30px;
  margin: 0 auto;

  a {
    color: color.scale(var.$main-color, $lightness: -5%);
  }

  &.first {
    margin-top: 64px;
  }

  &.last {
    margin-bottom: 64px;
  }

  @media (max-width: var.$mobile-breakpoint-m) {
    &.first {
      margin-top: 34px;
    }

    &.last {
      margin-bottom: 84px;
    }
  }
}

.legalInfoSectionTitle,
.title {
  margin: 0;
  font-size: 26px;
  font-weight: 700;

  @media (max-width: var.$mobile-breakpoint-m) {
    font-size: 21px;
  }
}

.section {
  margin-top: 36px;
  line-height: 1.5;
}

.sectionTitle {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  line-height: inherit;
}

.sectionContent {
  white-space: pre-line;
}

.hrContainer {
  padding: 0 30px;
}

.hr {
  height: 1px;
  margin: 35px 0;
  background-color: color.adjust(var.$border-color, $lightness: -5%);
}

.legalInfoSectionContent {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
  line-height: 1.5;
  white-space: nowrap;
}

.legalInfoSectionContentItem {
  flex-basis: 50%;
  padding-right: 20px;
}

.legalInfoSection {
  & + & {
    margin-top: 36px;
  }
}
