@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;

.modalOverlay {
  @include mix.absolute-inset(0);
  position: fixed;
  z-index: var.$modal-overlay-z-index;
  display: flex;
  overflow: auto;
  align-content: flex-start;
  align-items: flex-start;
  padding: 27px;
  backdrop-filter: blur(4px);
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-backdrop-filter: blur(5px);
  background-color: rgb(9 62 91 / 0.4);

  @media (max-width: var.$mobile-breakpoint-s) {
    padding: 20px 10px;
  }
}

.modal {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 460px;
  min-height: 300px;
  margin: auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgb(0 0 0 / 0.15);
  opacity: 1;
  outline: none;
}

.modalClose {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  margin: 18px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgb(171 171 171 / 0.2);
  cursor: pointer;

  @media (max-width: var.$tablet-breakpoint) {
    margin: 12px;
  }

  path {
    fill: currentColor;
  }
}

.modalTitles {
  width: 100%;
  padding: 15px 0;
  text-align: center;
}

.modalContent {
  padding: 53px 67px 49px;
  font-size: 14px;
  line-height: 20px;

  @media (max-width: var.$mobile-breakpoint-m) {
    padding-right: 40px;
    padding-left: 40px;
  }

  @media (max-width: var.$mobile-breakpoint-s) {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.title {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 26px;
  font-weight: 430;
  letter-spacing: -0.2px;
  line-height: 1.3;
  text-align: center;
}

.subtitle {
  margin-top: 0;
  margin-bottom: 36px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 1.3;
  opacity: 0.85;
  text-align: center;
}

.btnContainer {
  margin-top: 36px;
  text-align: center;
}

.input {
  & + & {
    margin-top: 20px;
  }
}

.inputContainerWithError {
  animation-duration: 0.15s;
  animation-iteration-count: 3;
  animation-name: wiggle;
  animation-timing-function: ease-out;
}

.termsCheckbox {
  display: inline-block;
  margin-top: 20px;
}

.footnote {
  margin-top: 16px;
  color: #ccc;
  font-size: 13px;
  line-height: 18px;
}

.link {
  font-weight: 600;
}

@keyframes wiggle {
  0%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-3px);
  }

  75% {
    transform: translateX(3px);
  }
}
