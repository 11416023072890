@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;

.mainLayout {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  min-height: inherit;
  flex-direction: column;

  @media (max-width: var.$desktop-breakpoint + 1) {
    padding-top: var.$mobile-header-height;
  }
}

.noopEnterAnimation {
  animation: noop-keyframes 0.1s;
}

.exitAnimation {
  animation-duration: 1s;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: fade-out;
  animation-timing-function: ease;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes noop-keyframes {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.btnContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0 35px 35px;

  @media (max-width: 960px) {
    &:not(.indexPageBtnContainer) {
      margin-right: 20px;
      margin-left: 20px;
    }
  }

  @media (max-width: var.$mobile-breakpoint-m) {
    right: 0;
    margin-bottom: 25px;
    text-align: center;
  }
}

.indexPageBtnContainer {
  @media (max-width: var.$mobile-breakpoint-m) {
    margin-bottom: 100px;
  }
}

.appStoreLink {
  position: fixed;
  bottom: 35px;
  right: 35px;
  z-index: 1000;
  display: inline-block;
  text-decoration: none;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 960px) {
    right: 50%;
    transform: translateX(50%);
  }
}

.appStoreLinkIcon {
  width: 180px;
  height: auto;
}

.signInBtn {
  min-width: 145px;
  height: 48.5px;
  justify-content: center;
  border-radius: 10px;
  font-size: 18px;
}

.indexPageCreateHashtagBtn {
  border: 1px solid;
  background: white;
  box-shadow: none;
}

.indexPageNavBar {
  box-shadow: none;
}

.btnCreate {
  box-shadow: 0 0 0 1px white;
}
