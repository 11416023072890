/* stylelint-disable selector-class-pattern */
@use 'src/styles/scss_variables' as var;

.Toastify__toast {
  min-width: 300px;
  max-width: 400px;
  padding: 20px;
  padding-right: 28px;
  box-shadow: 0 2px 5px rgb(0 0 0 / 0.1);
  color: var.$primary-text-color;
  font-size: 14px;
  line-height: 1.3;

  @media only screen and (max-width: var.$mobile-breakpoint-m) {
    width: 100%;
  }
}

.Toastify__toast--error {
  .Toastify__toast-icon svg {
    fill: var(--toastify-icon-color-error);
  }
}

.Toastify__toast--warning {
  .Toastify__toast-icon svg {
    fill: var(--toastify-icon-color-warning);
  }
}

.Toastify__toast--success {
  .Toastify__toast-icon svg {
    fill: var(--toastify-icon-color-success, var.$success-color);
  }
}

.Toastify__toast-container {
  top: const(safe-area-inset-top);
  top: env(safe-area-inset-top);
  display: flex;
  width: auto;
  max-width: 400px;
  flex-direction: column;
  align-items: flex-end;
  padding: 0;
  padding-top: 1em;

  @media only screen and (max-width: var.$mobile-breakpoint-m) {
    &--top-right {
      left: 1rem !important;
    }
  }
}

.Toastify__close-button {
  position: absolute;
  top: 8px;
  right: 7px;
  display: flex;
  width: 10px;
  height: 10px;

  @media only screen and (max-width: var.$mobile-breakpoint-m) {
    width: auto;
    height: auto;

    > svg {
      width: 17px;
      height: 17px;
    }
  }
}

.Toastify__toast-body {
  padding: 0;
}

.Toastify__toast-icon {
  margin-inline-end: 12px;
}

.Toastify__progress-bar[aria-hidden='true'] {
  opacity: 0;
  visibility: hidden;
}
