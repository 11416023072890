// Colors
$primary-text-color: black;
$light-text-color: #7f8082;
$border-color: #ebebee;
$transparent-white: rgb(255 255 255 / 0);
$success-color: #34c759;
$warning-color: #ff9500;
$main-color: #ffd602;
$usc-gold-color: #fc0;
$golden-yellow: #ffdf00;

// Sizes
$container-width: 1000px;
$container-mobile-h-padding: 20px;
$mobile-header-height: 78px;
$header-height: 100px;

// Responsive breakpoints
$mobile-breakpoint-s: 375px;
$mobile-breakpoint-m: 480px;
$tablet-breakpoint: 768px;
$desktop-breakpoint: 1024px;

// -
$files-view-smaller-mobile-breakpoint: 465px;
$files-view-larger-mobile-breakpoint: 600px;

// z-indexes
$navbar-z-index: 10;
$modal-overlay-z-index: $navbar-z-index + 1;
$progress-bar-z-index: $modal-overlay-z-index + 1;
