@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;

.container {
  width: 100%;
  max-width: var.$container-width;
  padding: 40px 0 80px;
  margin: 0 auto;
}

.narrowContainer {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  @media (max-width: 968px) {
    padding: 0 50px;
  }

  @media (max-width: 640px) {
    padding: 0 30px;
  }
}

.plans {
  display: flex;
  justify-content: center;
  margin: 0 50px;
  gap: 35px;

  @media (max-width: 968px) {
    flex-wrap: wrap;
    gap: 25px;
  }

  + * {
    margin-top: 70px;
  }
}

.plan {
  max-width: 280px;
  flex-grow: 1;
}

.titles {
  margin: 70px 0 75px;
  hyphens: initial;
  text-align: center;
}

.title {
  margin: 0;
  margin-bottom: 15px;
  font-size: 56px;
  font-weight: 700;

  span {
    color: var.$main-color;
  }

  @media (max-width: var.$tablet-breakpoint) {
    font-size: 36px;
  }
}

.subtitle {
  margin: 0;
  color: #6b6f76;
  font-size: 20px;
}

.billingPeriodSwitcher {
  margin-bottom: 35px;
}

.spinnerOverlay {
  position: fixed;
  z-index: var.$navbar-z-index + 1;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-backdrop-filter: blur(5px);
  background-color: rgb(9 62 91 / 0.3);
}

.spinner {
  width: 60px;
  height: 60px;
  animation-duration: 0.5s;
  color: var.$main-color;
}

.plansPreloaderContainer {
  display: flex;
  min-height: 330px;
  flex-flow: column wrap;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 80px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.warning {
  margin: 50px auto;
  color: var.$warning-color;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  text-wrap: balance;
}
