@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;

.itemIcon {
  width: 20px;
  height: auto;
  margin-right: 10px;
  color: var.$main-color;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.hashtagName {
  overflow: hidden;
  width: 100%;
  align-items: center;
  margin-top: 2px;
  font-size: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hashtagOwnerNote {
  width: 100%;
  margin-top: 2px;
  font-size: 14px;
  font-weight: 400;
  opacity: 0.4;
}

.editModeToggle {
  position: absolute;
  top: 45px;
  right: 12px;
  padding: 7px;
  font-size: 14px;
  font-weight: 700;
  opacity: 0.5;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 1;
  }
}

.actions {
  position: absolute;
  top: 1px;
  right: 15px;
  bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  margin-left: auto;
  background: linear-gradient(to right, rgb(255 255 255 / 0.2), white 10px);
}

.action {
  cursor: pointer;
}

.actionRemove {
  width: 16px;
  color: #ff3f3f;
}

.panelItemText {
  overflow: hidden;
}

.panelItemOuter {
  position: relative;

  & + & {
    &::before {
      position: absolute;
      top: 0;
      right: 0;
      left: 52px;
      border-top: 1px solid #d2d2d2;
      content: '';
    }
  }
}

.container {
  position: relative;
  height: auto;

  @include mix.perfect-scrollbar-size(5px, 10px);

  &:global(.ps--active-y) {
    padding-right: 14px;
    margin-right: -7px;
  }

  :global {
    .ps__rail-y {
      &:hover,
      &:focus,
      &.ps--clicking {
        background-color: #f1f1f1;
      }
    }
  }
}

.buttons {
  padding-top: 22px;
}

.btn {
  width: 100%;
  justify-content: center;

  & + & {
    margin-top: 15px;
  }

  &.btn {
    height: 42px;
    color: black;
    font-size: 16px;
  }
}

.btnJoin:hover {
  background-color: #ffc107;
  color: black;
}

.btnCreate.btnCreate {
  background-color: #ffedb3;

  &:hover {
    background-color: #ffe082;
    color: black;
  }

  [class*='btnIconPlus'] {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    margin-right: 8px;
  }
}

.noData {
  color: #ccc;
  cursor: default;
}
