@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;
@use 'sass:color';
@use 'sass:math';

$light-text-color: #7f8082;
$regular-text-color: #282a30;
$border-color: color.change(#929cad, $alpha: 0.3);

.hero {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.container {
  padding-top: 64px;
  padding-bottom: 80px;
  color: $regular-text-color;
  font-family: 'Helvetica Neue', -apple-system, sans-serif;

  @media (max-width: 700px) {
    padding-top: 34px;
  }
}

.narrowContainer {
  width: 100%;
  max-width: 700px;
  padding: 0 30px;
  margin: 0 auto;
}

.heroMainContent {
  display: flex;
  align-items: center;
  padding: 20px 30px;
  margin: 0 0 50px;

  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
}

.heroTitle {
  flex-basis: math.div(360, 840) * 100%;
  flex-shrink: 0;
  margin: 0;
  font-size: 56px;
  font-weight: 800;
  hyphens: initial;

  @media (max-width: 900px) {
    flex-basis: 100%;
    font-size: 40px;
    text-align: center;
  }

  em {
    color: var.$main-color;
    font-style: normal;
  }
}

.heroImageContainer {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.heroImage {
}

.heroFootnote {
  padding: 50px 20px 60px;
  border-top: 1px solid var.$border-color;
  color: $light-text-color;
  line-height: 1.5;
  text-align: center;
}

.heroFootnoteInner {
  max-width: 440px;
  margin: 0 auto;
}

.section {
  display: flex;
  width: 100%;
  max-width: 700px;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  padding: 60px 30px;
  margin: 0 auto;

  & + & {
    border-top: 1px solid $border-color;
  }

  &:nth-child(2n + 1) > *:first-child {
    order: 1;
  }

  @media (max-width: 700px) {
    flex-wrap: wrap;
    padding: 40px 30px;

    .sectionContent {
      flex-basis: 100%;
      order: -1;
    }
  }

  @media (max-width: var.$mobile-breakpoint-m) {
    padding: 30px 20px;
  }
}

.sectionFirst {
  padding-top: 30px;
}

.sectionLast {
  max-width: 1040px;
  padding-top: 90px;

  .sectionContent {
    padding: 20px 25px;
  }
}

.sectionImage {
  object-fit: contain;
}

.sectionImageContainer {
  display: flex;
  flex-basis: calc(52.5% - 0.525 * 30px);

  @media (max-width: 700px) {
    max-height: 70vh;
    flex-basis: 100%;
    margin-top: 20px;
  }

  @media (max-width: var.$mobile-breakpoint-m) {
    max-height: 45vh;
  }
}

.sectionContent {
  flex-basis: calc(47.5% - 0.475 * 30px);
  flex-shrink: 0;
}

.sectionContentInner {
  max-width: 300px;

  @media (max-width: 700px) {
    max-width: none;
  }
}

.sectionTitle {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
}

.sectionText {
  margin-top: 8px;
  color: $light-text-color;
  hyphens: initial;
  line-height: 1.5;
}

.features {
  display: grid;
  padding: 90px 30px;
  column-gap: 30px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 640px) {
    display: block;
    padding: 40px 30px;
  }
}

.feature {
  line-height: 1.5;

  @media (max-width: 640px) {
    & + & {
      margin-top: 30px;
    }
  }
}

.featureTitle {
  margin: 0;
  font-weight: 700;
}

.featureText {
  margin-top: 8px;
  color: $light-text-color;
}

.hrContainer {
  width: 100%;
  max-width: 700px;
  padding: 0 30px;
  margin: 0 auto;
}

.hr {
  height: 1px;
  border: none;
  margin: 0;
  background-color: $border-color;
}

.featureIconWrapper {
  display: flex;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  background-color: var.$main-color;
  border-radius: 50%;
  box-shadow: 0 10px 20px -10px #6e79d6;

  + * {
    margin-top: 20px;
  }
}

.featureIcon {
  width: 18px;
  height: 18px;
  color: white;

  &,
  path {
    fill: currentColor;
  }
}

.contactUsContainer {
  padding-top: 80px;

  @media (max-width: 640px) {
    padding-top: 40px;
  }
}

.contactUsTitle {
  color: var.$main-color;
}
